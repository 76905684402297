import { graphql } from 'gatsby';
import React from 'react';
import SingleTemplate from './single';
import { PAGE_TYPE, PAGE_PREFIX } from '../utils/constants';
import { getAcademyArticleMeta } from '../utils/helpers';
import AcademyProxy from '../components/proxy/academy-blocks';

const AcademyArticleTemplate = props => {
  const { site, article, markdownRemark } = props.data;

  const seo = getAcademyArticleMeta({
    pathname: props.location.pathname,
    siteMetadata: site.siteMetadata,
    frontmatter: markdownRemark.frontmatter,
    article,
  });

  return (
    <SingleTemplate
      seo={seo}
      pageType={PAGE_TYPE.AcademyArticle}
      pagePrefix={PAGE_PREFIX.Academy}
      proxy={AcademyProxy}
      {...props}
    />
  );
};

export default AcademyArticleTemplate;

export const academyArticlePageQuery = graphql`
  query AcademyArticlePageQuery($id: Int, $glossaryItems: [Int]) {
    article: academyArticles(Id: { eq: $id }) {
      Id
      Title
      Description
      Content
      Category
      UrlPath
      TopicName
      ListTitle
      GlossaryItems
      RelatedItems
      RelatedArticles {
        Id
        TopicName
        ListTitle
        UrlPath
      }
      MetaTitle
      MetaDesc
    }
    glossaryItems: allGlossaryItems(filter: { Id: { in: $glossaryItems } }) {
      edges {
        node {
          Id
          Name
          Content
          Description
          LinkToArticle
        }
      }
    }
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: "/academy-article/" } }) {
      ...PageMarkdownFragment
    }
    ...ConfigFragment
  }
`;
